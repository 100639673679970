<template>
  <section>
    <b-container>
      <b-row>
        <b-col>
          <b-card>
            <h2>{{ $t("items.metadata") }}</h2>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-button variant="primary" class="mr-2" @click="createNew"
              ><i class="fas fa-plus"></i> New</b-button
            >
            <Back-Button class="mr-2"></Back-Button>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <h4>Special txt</h4>
            <b-table
              hover
              striped
              sticky-header
              outlined
              :items="getSpecialTxt"
              :fields="fields"
            ></b-table>
          </b-card>
        </b-col>
        <b-col>
          <b-card>
            <h4>Polish types</h4>
            <b-table
              hover
              striped
              sticky-header
              outlined
              :items="getPolishTypes"
              :fields="fields"
            ></b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BackButton from "@/components/Buttons/BackButton";

export default {
  name: "MetaData",
  components: {
    BackButton
  },
  data() {
    return {
      fields: [
        {
          key: "name",
          label: "Name"
        },
        {
          key: "isActive",
          label: "Active"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["getSpecialTxt", "getPolishTypes"])
  },
  methods: {
    ...mapActions(["loadMetaData"]),
    createNew() {
      this.$router.push(`/admin/items/metadata/new`);
    }
  },
  created() {
    this.loadMetaData();
  }
};
</script>

<style></style>
